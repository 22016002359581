/* eslint-disable */
import {FC} from 'react';
import {Svg} from 'modules/theme/components/svg/Svg'
import {Checkbox as MuiCheckbox} from '@mui/material';
import CheckedIcon from 'modules/theme/components/fields/checkbox/svg/checkedIcon.svg';
import DefaultIcon from 'modules/theme/components/fields/checkbox/svg/defaultIcon.svg';

interface ICheckbox {
    disabled?: boolean;
    errorMsg?: string;
    isChecked: boolean;
    onClick: (isChecked: boolean) => void;
    testId?: string;
    checkboxTopAlign?: boolean;
}

export const Checkbox: FC<ICheckbox> = ({ isChecked, disabled, onClick, testId ='checkbox', checkboxTopAlign }) => {
    return (
        <MuiCheckbox
            sx={{
                marginTop: checkboxTopAlign && '0',
            }}
            checked={isChecked}
            disabled={disabled}
            onChange={(e) => onClick(e.target.checked)}
            icon={<Svg icon component={DefaultIcon} width={18} height={18}/>}
            checkedIcon={<Svg icon component={CheckedIcon} width={18} height={18} />}
            data-testid={testId}
        />
    );
};
