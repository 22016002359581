export enum EItemListId {
    ASSOCIATED_LISTINGS = 'associated listings',
    DIRECTORY_SEARCH_RESULT = 'Directory search results',
    CLASSIFIED_SEARCH_RESULTS = 'classified_search_results',
    SIMILAR_LISTINGS_FROM_SELLER = 'similar listings from seller',
    SIMILAR_LISTINGS = 'similar listings',
    EXCLUSIVE_LISTINGS = 'exclusive listings',
    RECOMMENDED_LISTINGS = 'recommended listings',
    LATEST_LISTINGS = 'latest_listings'
}
