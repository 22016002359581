import {FC} from 'react';
import {Svg} from 'modules/theme/components/svg/Svg';
import Icon from 'modules/theme/components/assets/blankPlaceholder/svg/Figure.svg';

interface IFigure {
    width: number | string;
}

export const Figure: FC<IFigure> = ({width}) => {
    return <Svg icon component={Icon} width={width}/>;
};
