import { FC } from 'react';
import { IconButton, Theme } from '@mui/material';
import { Svg } from 'modules/theme/components/svg/Svg';
import CloseIcon from 'modules/theme/components/icons/basic/Close.svg';

interface ICloseButton {
    onClick: () => void;
}

export const CloseButton: FC<ICloseButton> = ({ onClick }) => {
    return (
        <IconButton
            onClick={onClick}
            size="small"
            sx={{
                background: 'transparent',
                color: (theme: Theme) => `${theme.palette.labelPrimary.main}`,
                padding: 0,
            }}
        >
            <Svg icon component={CloseIcon} />
        </IconButton>
    );
};
