export enum ESubCategory {
  STUDIO_APARTMENT = 'STUDIO_APARTMENT', //9
  DOUBLE_STUDIO_APARTMENT = 'DOUBLE_STUDIO_APARTMENT', //10
  ONE_ROOM_APARTMENT = 'ONE_ROOM_APARTMENT', //11
  TWO_ROOM_APARTMENT = 'TWO_ROOM_APARTMENT', //12
  THREE_ROOM_APARTMENT = 'THREE_ROOM_APARTMENT', //13
  FOUR_ROOM_APARTMENT = 'FOUR_ROOM_APARTMENT', //14
  FIVE_PLUS_ROOM_APARTMENT = 'FIVE_PLUS_ROOM_APARTMENT', //15
  HOLIDAY_APARTMENT = 'HOLIDAY_APARTMENT', //16
  LOFT = 'LOFT', //17
  MAISONETTE = 'MAISONETTE', //18
  OTHER_TYPE_OF_APARTMENT = 'OTHER_TYPE_OF_APARTMENT', //19
  FAMILY_HOUSE = 'FAMILY_HOUSE', //20
  COUNTRY_HOUSE = 'COUNTRY_HOUSE', //21
  FARM_SETTLEMENT = 'FARM_SETTLEMENT', //22
  MOBILE_HOUSE = 'MOBILE_HOUSE', //23
  HOUSEBOAT = 'HOUSEBOAT', //24
  OTHER_OBJECT_FOR_HOUSING = 'OTHER_OBJECT_FOR_HOUSING', //25
  CABIN_AND_LOG_CABIN = 'CABIN_AND_LOG_CABIN', //26
  COTTAGE_AND_RECREATION_HOUSE = 'COTTAGE_AND_RECREATION_HOUSE', //27
  GARDEN_HUT = 'GARDEN_HUT', //28
  OTHER_RECREATIONAL_OBJECT = 'OTHER_RECREATIONAL_OBJECT', //29
  LAND_FOR_FAMILY_HOUSE = 'LAND_FOR_FAMILY_HOUSE', //30
  RECREATIONAL_LAND = 'RECREATIONAL_LAND', //31
  LAND_FOR_HOUSING_CONSTRUCTION = 'LAND_FOR_HOUSING_CONSTRUCTION', //32
  LAND_FOR_CIVIC_AMENITIES = 'LAND_FOR_CIVIC_AMENITIES', //33
  COMMERCIAL_ZONE = 'COMMERCIAL_ZONE', //34
  INDUSTRIAL_ZONE = 'INDUSTRIAL_ZONE', //35
  MIXED_ZONE = 'MIXED_ZONE', //36
  GARDEN = 'GARDEN', //37
  ORCHARD = 'ORCHARD', //38
  VINEYARD_AND_HOP_GARDEN = 'VINEYARD_AND_HOP_GARDEN', //39
  MEADOW_AND_PASTURE = 'MEADOW_AND_PASTURE', //40
  ARABLE_LAND = 'ARABLE_LAND', //41
  FOREST_LAND = 'FOREST_LAND', //42
  POND_AND_LAKE = 'POND_AND_LAKE', //43
  LAND_FOR_ADVERTISING = 'LAND_FOR_ADVERTISING', //44
  OTHER_TYPE_OF_LAND = 'OTHER_TYPE_OF_LAND', //45
  OFFICES = 'OFFICES', //46
  BUSINESS_SPACES = 'BUSINESS_SPACES', //47
  RESTAURANT_SPACES = 'RESTAURANT_SPACES', //48
  STORAGE_AREAS = 'STORAGE_AREAS', //49
  SPACE_FOR_PRODUCTION = 'SPACE_FOR_PRODUCTION', //50
  REPAIR_AREA = 'REPAIR_AREA', //51
  SPORTS_FACILITIES = 'SPORTS_FACILITIES', //52
  SPACE_FOR_ADVERTISEMENT = 'SPACE_FOR_ADVERTISEMENT', //53
  OTHER_TYPE_OF_SPACE = 'OTHER_TYPE_OF_SPACE', //54
  APARTMENT_HOUSE = 'APARTMENT_HOUSE', //55
  RENTAL_HOUSE = 'RENTAL_HOUSE', //56
  OFFICE_BUILDING = 'OFFICE_BUILDING', //57
  COMMERCIAL_OBJECT = 'COMMERCIAL_OBJECT', //58
  RESTAURANT = 'RESTAURANT', //59
  POLYFUNCTIONAL_BUILDING = 'POLYFUNCTIONAL_BUILDING', //60
  WAREHOUSE = 'WAREHOUSE', //61
  MANUFACTURING_FACILITY = 'MANUFACTURING_FACILITY', //62
  REPAIR_FACILITY = 'REPAIR_FACILITY', //63
  OBJECT_FOR_BREEDING_ANIMALS = 'OBJECT_FOR_BREEDING_ANIMALS', //64
  HOTEL_AND_PENSION = 'HOTEL_AND_PENSION', //65
  SPA = 'SPA', //66
  HISTORICAL_OBJECT = 'HISTORICAL_OBJECT', //67
  COMMERCIAL_FACILITY = 'COMMERCIAL_FACILITY', //68
  SPORT_OBJECT = 'SPORT_OBJECT', //69
  SMALL_POWER_STATION = 'SMALL_POWER_STATION', //70
  GAS_STATION = 'GAS_STATION', //71
  MOBILE_CELLS_AND_STANDS = 'MOBILE_CELLS_AND_STANDS', //72
  OTHER_TYPE_OF_OBJECT = 'OTHER_TYPE_OF_OBJECT', //73
  DETACHED_GARAGE = 'DETACHED_GARAGE', //74
  CONSOLIDATED_GROUND = 'CONSOLIDATED_GROUND', //78
  AGRICULTURAL_OBJECT = 'AGRICULTURAL_OBJECT', //24719
  OTHER_LAND_TO_BUILDING = 'OTHER_LAND_TO_BUILDING', //30746
  OTHER_AGRICULTURAL_LAND = 'OTHER_AGRICULTURAL_LAND', //30747
  SECONDARY_STUDIO_APARTMENT = 'SECONDARY_STUDIO_APARTMENT', //300000
  SECONDARY_THREE_ROOM_APARTMENT = 'SECONDARY_THREE_ROOM_APARTMENT', //300001
  SECONDARY_LAND_FOR_FAMILY_HOUSES = 'SECONDARY_LAND_FOR_FAMILY_HOUSES', //400000
  SECONDARY_RECREATIONAL_LAND = 'SECONDARY_RECREATIONAL_LAND', //400001
  SECONDARY_COMMERCIAL_PLOTS = 'SECONDARY_COMMERCIAL_PLOTS', //400002
  SECONDARY_GARDEN = 'SECONDARY_GARDEN', //400003
  SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS = 'SECONDARY_ORCHARDS_VINEYARDS_HOP_FIELDS', //400004
  SECONDARY_AGRICULTURAL_AND_FOREST_LAND = 'SECONDARY_AGRICULTURAL_AND_FOREST_LAND', //400005
  SECONDARY_GARAGE_AND_PARKING = 'SECONDARY_GARAGE_AND_PARKING', //500000
  SECONDARY_OFFICES_AND_ADMINISTRATION = 'SECONDARY_OFFICES_AND_ADMINISTRATION', //500001,
  SECONDARY_THE_SHOP = 'SECONDARY_THE_SHOP', //500002
  SECONDARY_A_RESTAURANT = 'SECONDARY_A_RESTAURANT', //500003
  SECONDARY_WAREHOUSES = 'SECONDARY_WAREHOUSES', //500004
  SECONDARY_PRODUCTION = 'SECONDARY_PRODUCTION', //500005
  SECONDARY_ANOTHER_SPACE_AND_OBJECT = 'SECONDARY_ANOTHER_SPACE_AND_OBJECT', //500006
  SECONDARY_HOTEL_GUESTHOUSE = 'SECONDARY_HOTEL_GUESTHOUSE' //500008
}
