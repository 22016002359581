/* eslint-disable */
import {Svg} from 'modules/theme/components/svg/Svg';
import {InputAdornment, TextField} from '@mui/material';
import {FC, FunctionComponent, InputHTMLAttributes, SVGProps} from 'react';

interface IInput {
    label?: string;
    value: string;
    inputRef?: any;
    errorMsg?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    onBlur?: () => void;
    onFocus?: () => void;
    placeholder?: string;
    labelShrink?: boolean;
    textEllipsis?: boolean;
    onEnterPress?: () => void;
    endIconStopPropagation?: boolean;
    onChange: (value: string) => void;
    type?: InputHTMLAttributes<string>['type'];
    endIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    startIcon?: FunctionComponent<SVGProps<SVGSVGElement>>;
    testId?: string;
    onStartIconClick?: () => void;
    onEndIconClick?: () => void;
    autoComplete?: string;
}

export const Input: FC<IInput> = ({
    type,
    value,
    label,
    onBlur,
    onFocus,
    endIcon,
    errorMsg,
    disabled,
    onChange,
    inputRef,
    startIcon,
    autoFocus,
    labelShrink,
    placeholder,
    textEllipsis,
    onEnterPress,
    onStartIconClick,
    onEndIconClick,
    testId = 'input',
    autoComplete,
    endIconStopPropagation,
}) => {
    if (label && startIcon) {
        throw Error('You cannot use startIcon and label at the same time');
    }

    return (
        <TextField
            fullWidth
            type={type}
            label={label}
            onBlur={onBlur}
            onFocus={onFocus}
            error={!!errorMsg}
            value={value || ''}
            disabled={disabled}
            inputRef={inputRef}
            helperText={errorMsg}
            autoFocus={autoFocus}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onChange={(e) => onChange(e.target.value)}
            onKeyDown={(ev) => ev.keyCode === 13 && onEnterPress && onEnterPress()}
            InputLabelProps={{ shrink: labelShrink }}
            sx={{
                '& .MuiInputBase-input': {
                    overflow: textEllipsis && 'hidden',
                    textOverflow: textEllipsis && 'ellipsis',
                }
              }}
            data-test-id={testId}
            InputProps={{
                inputProps: type === 'number'
                    && {
                        min: 0,
                        step: 1,
                        pattern: '[0-9]*',
                    },
                startAdornment: startIcon && (
                    <InputAdornment
                        position={'start'}
                        onClick={onStartIconClick}
                        sx={{
                            color: 'currentcolor'
                        }}
                    >
                        <Svg icon
                            component={startIcon}
                            width={20}
                        />
                    </InputAdornment>
                ),
                endAdornment: endIcon && (
                    <InputAdornment
                        position={'end'}
                        onClick={(event) => {
                            endIconStopPropagation && event.stopPropagation();
                        }}
                    >
                        <Svg icon
                            component={endIcon}
                            width={20}
                            onIconClick={onEndIconClick}
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};
