import {EViewListItemVariant} from 'modules/gtmEvents/enums/EViewListItemVariant';

export const getGtmLocationStringHelper = (
    country?: string,
    county?: string,
    region?: string,
    city?: string,
): string => {
    let location = '';
    if (country) {
        location = country;
    }

    if (county) {
        if (location) {
            location = `${location}, ${county}`;
        } else {
            location = county;
        }
    }

    if (region) {
        if (location) {
            location = `${location}, ${region}`;
        } else {
            location = region;
        }
    }

    if (city) {
        if (location) {
            location = `${location}, ${city}`;
        } else {
            location = city;
        }
    }

    return location;
};

export const getItemVariant = (previewPhotoUrl?: string, hasVideoUrl?: boolean): EViewListItemVariant => {
    if (previewPhotoUrl) {
        if (hasVideoUrl) {
            return EViewListItemVariant.PHOTO_AND_VIDEO;
        }
        return EViewListItemVariant.PHOTO_ONLY;
    }

    return EViewListItemVariant.NO_IMAGERY;
};

export const getOrderIndex = (currentIndex: number, currentPage: number, limit: number): number => {
    return currentIndex + 1 + (currentPage - 1) * limit;
};
