import { FC, ReactNode } from 'react';
import {
    FormGroup,
    FormControlLabel,
    FormControl,
    FormHelperText,
} from '@mui/material';
import { Checkbox as CheckboxCommon } from 'modules/theme/components/fields/checkbox/Checkbox';

interface ICheckboxWithLabel {
    label: ReactNode;
    disabled?: boolean;
    isChecked: boolean;
    onClick: (isChecked: boolean) => void;
    checkboxTopAlign?: boolean;
    errMsg?: string;
    onBlur?: () => void;
}

export const CheckboxWithLabel: FC<ICheckboxWithLabel> = ({
    label,
    onClick,
    disabled,
    isChecked,
    checkboxTopAlign,
    errMsg,
    onBlur,
}) => {
    const checkBox = (
        <CheckboxCommon
            checkboxTopAlign={checkboxTopAlign}
            onClick={onClick}
            disabled={disabled}
            isChecked={isChecked}
        />
    );

    return (
        <FormControl error={!!errMsg}>
            <FormGroup
                sx={{ display: 'inline-block' }}
                onBlur={() => {
                    if (onBlur) {
                        onBlur();
                    }
                }}
            >
                <FormControlLabel control={checkBox} label={label} />
                {!!errMsg && <FormHelperText>{errMsg}</FormHelperText>}
            </FormGroup>
        </FormControl>
    );
};
